import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Close_Square, unfit_small } from '../../../../utils/image'

export default function Appannouce() {
    const [isVisible, setIsVisible] = useState(true);
    const [isSlidingUp, setIsSlidingUp] = useState(false);

    const handleClose = () => {
        setIsVisible(false);
        setIsSlidingUp(true); 
    };

    useEffect(() => {
        if (isSlidingUp) {
          const headerWrapper = document.querySelector(".header__wrapper");
          if (headerWrapper) {
            headerWrapper.style.top = "0px";
          }
        }
      }, [isSlidingUp]);

    return (
        isVisible && (
            <div className={`app_anouncement ${isSlidingUp ? 'slide-up' : ''}`}>
                <div className='close_icon' onClick={handleClose}>
                    <img src={Close_Square} alt="close icon"/>
                </div>
                <div className='app_annouce_text'>
                    <img src={unfit_small} alt="Logo" />
                    <p>
                        Get 10% off your first order + free professional consultation!
                    </p>
                </div>
                <Link to="https://unfitmart.page.link/RaBWL8y1AQ1YCMyE6" className='btn_primary'>
                    Get App
                </Link>
            </div>
        )
    )
}
