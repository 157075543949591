import React, { useEffect, useState } from "react";
import "./categoryDetails.css";
// import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assests/icons/arrowTop.svg";
// import { ReactComponent as GridView } from "../../../assests/icons/gridview.svg";
// import { ReactComponent as ListView } from "../../../assests/icons/listview.svg";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Close_Square,
  filter_icon,
  fl_arrow,
  data_not_found,
} from "../../../utils/image";
import ArrowTop from "../../../assests/icons/arrowTop.svg";
import ProductCard from "../../layout/product/productCard/productCard";
import CloseIcon from "../../../assests/icons/close.png";
import CustomLoader from "../../common/customLoader/customLoader";
import ToastMessage from "../../common/toastMessage/toastMessage";
//import Loader from '../loader/loadingCard';

const CategoryFilter = ({
  categories,
  productLength,
  productList,
  selectedData,
  setCountItems,
  setSelectedData,
  visibleProducts,
  filterListData,
  handleFilterInputChange,
  loading,
  bestSellerProductData,
  doesAPIHaveFilter,
}) => {
  const [isMobileFilterActive, setisMobileFilterActive] = useState(false);
  const [isToastMessage, setIsToastMessage] = useState(false);

  let brandsData = [];
  const [isGridViewActive, setIsGridViewActive] = useState(true); // Default active state
  const [currentSelected, setCurrentSelected] = useState([]);
  const [isSeeAllFiters, setIsSeeAllFiters] = useState({
    Brands: false,
    Categories: false,
    Flavours: false,
  });
  const [activeIndex, setActiveIndex] = useState(0);


  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const toggleGridView = () => {
    setIsGridViewActive(true);
  };

  const toggleListView = () => {
    setIsGridViewActive(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  if (productList.CategoryName) {
    //subCategory
    brandsData = productList.ProductList;
  } else {
    const unique = Array.from(
      new Set(productList[0]?.map((item) => item.id))
    )?.map((id) => {
      return productList[0].find((item) => item.id === id);
    });
    brandsData = unique;
  }
  // console.log('brandsData', brandsData)
  const handleMobileFilterToggle = () => {
    setisMobileFilterActive(!isMobileFilterActive);
  };
  //console.log(brandsData,"brandsData")

  const handleClick = (categoryName) => {
    let image = categories.filter(
      (category) => category.parent_category_name === categoryName
    )[0];
    // console.log(image)
    setSelectedData({
      categoryName: categoryName,
      categoryImage: image.CategoryImage,
      subCategory: "",
      subDetails: "",
      id: "",
    });
  };

  const handleClick2 = (categoryName, categoryId) => {
    const params = new URLSearchParams(location.search);
    params.set("id", categoryId);
    navigate(`${location.pathname}?${params.toString()}`);

    setSelectedData({
      categoryName: categoryName,
      categoryId: categoryId,
      categoryImage: "",
      subCategory: "",
      subDetails: "",
      id: "",
    });
  };



  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };


  const handleSeeAllFilters = (key) => {
    setIsSeeAllFiters((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const mobileFilterConfig = {
    Categories: {
      data: filterListData?.Categories,
      render: (item, index) => (
        <li
          className="brand__filter__item"
          key={`${index}-${item.id}`}
          onClick={(e) => {
            e.stopPropagation();
            handleClick2(item.category_name, item.id);
          }}
        >
          {item.category_name}
        </li>
      ),
    },
    Brands: {
      data: filterListData?.Brands,
      render: (item, index) => (
        <li
          className="brand__filter__item"
          key={`${index}-${item.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            id={item.id}
            type="checkbox"
            onChange={(e) => {
              e.stopPropagation();
              handleFilterInputChange(e, "Brands");
            }}
          />
          <label>{item.name}</label>
        </li>
      ),
    },
    Flavours: {
      data: filterListData?.Flavours,
      render: (item) => (
        <li
          className="brand__filter__item"
          key={item.FlavourName}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            id={item.FlavourName}
            type="checkbox"
            onChange={(e) => {
              e.stopPropagation();
              handleFilterInputChange(e, "Flavours");
            }}
          />
          <label>{item.FlavourName}</label>
        </li>
      ),
    },
    Price: {
      data: [
        "₹0 - ₹1000",
        "₹1000 - ₹2000",
        "₹2000 - ₹3000",
        "₹3000 - ₹4000",
        "₹4000 - ₹5000",
      ],
      render: (item) => (
        <li className="brand__filter__item" key={item}>
          <input id={item} type="checkbox" />
          <label>{item}</label>
        </li>
      ),
    },
  };
  if (loading) {
    document.querySelector(".opacity__main__container")?.classList.remove("active");
    return <CustomLoader />;
  }

  let noDataProd = productList.forEach((product) => {
    return product
  });

    return (
    <>
      <div className="brand__filter__wrapper page-width">
        {doesAPIHaveFilter && (
          <aside className="brand__filters hide">
            {/* <div className="br_title_mob">
          <img src={fl_arrow} />
          <h4>Category</h4>
          <div className="close_filter">
              <img className="close" src={CloseIcon}/>
          </div>
        </div> */}
            <div className="br_filter_inner">
              <div className="brand__category">
                <details className="brand__category__item" open>
                  <summary>
                    <h3>Category</h3>
                    <Arrow className="arrow-icon" />
                  </summary>
 
                  <ul className="brand__categorys__filter">
                    {filterListData?.Categories?.map((category) => (
                      <li
                        className="brand__filter__item"
                        key={category.id}
                        onClick={() =>
                          handleClick2(category.category_name, category.id)
                        }
                      >
                        {/* <Link
                      to={`/category/${category.category_name.replace(
                        /[^a-zA-Z0-9\s&-]/g,
                        ""
                      )
                        .replace(/\s+/g, "-")
                        .replace(/-$/, "")
                        .replace(/--+/g, "-")
                        .toLowerCase()}`}
                    > */}
                        {category.category_name}
                        {/* </Link> */}
                      </li>
                    ))}

                    {/* <li className="brand__filter__item see__all">
                  <Link to="/category/see-all">See all</Link>
                </li> */}
                    <li
                      className="brand__filter__item see__all"
                      onClick={() => handleSeeAllFilters("Categories")}
                    >
                      See {isSeeAllFiters.Categories ? "less" : "all"}
                    </li>
                  </ul>
                </details>
              </div>

       
              <div className="brand__flavours brand__category">
                <details className="brand__category__item falvour__item">
                  <summary>
                    <h3>Brands</h3>
                    <Arrow className="arrow-icon" />
                  </summary>
                  <ul className="brand__categorys__filter flavours__filter">
                    {filterListData?.Brands?.map((brand) => (
                      <li className="brand__filter__item" key={brand.id}>
                        <input
                          id={brand.id}
                          type="checkbox"
                          onChange={(e) => handleFilterInputChange(e, "Brands")}
                        />
                        <label htmlFor={brand.id}>{brand.name}</label>
                      </li>
                    ))}
                    <li
                      className="brand__filter__item see__all"
                      onClick={() => handleSeeAllFilters("Brands")}
                    >
                      See {isSeeAllFiters.Brands ? "less" : "all"}
                    </li>
                  </ul>
                </details>
              </div>

              <div className="brand__price brand__category">
                <details className="brand__category__item price__item">
                  <summary>
                    <h3>Price</h3>
                    <Arrow className="arrow-icon" />
                  </summary>
                  <ul className="brand__categorys__filter brand__price">
                    <li className="brand__filter__item">
                      <input
                        type="checkbox"
                        id="1000"
                        onChange={(e) => handleFilterInputChange(e, "Price")}
                      />{" "}
                      ₹0 - ₹1000
                    </li>
                    <li className="brand__filter__item">
                      <input
                        type="checkbox"
                        id="2000"
                        onChange={(e) => handleFilterInputChange(e, "Price")}
                      />{" "}
                      ₹1000 - ₹2000
                    </li>
                    <li className="brand__filter__item">
                      <input
                        type="checkbox"
                        id="3000"
                        onChange={(e) => handleFilterInputChange(e, "Price")}
                      />{" "}
                      ₹2000 - ₹3000
                    </li>
                    <li className="brand__filter__item">
                      <input
                        type="checkbox"
                        id="4000"
                        onChange={(e) => handleFilterInputChange(e, "Price")}
                      />{" "}
                      ₹3000 - ₹4000
                    </li>
                    <li className="brand__filter__item">
                      <input
                        type="checkbox"
                        id="5000"
                        onChange={(e) => handleFilterInputChange(e, "Price")}
                      />{" "}
                      ₹4000 - ₹5000
                    </li>
                    {/* <li className="brand__filter__item see__all">See all</li> */}
                  </ul>
                </details>
              </div>

            </div>
          </aside>
        )}

        <div
          className={`brand__filters_mob ${isMobileFilterActive && "active"}`}
        >
          <div
            className="filter_overlay"
            onClick={handleMobileFilterToggle}
          ></div>
          <div className="br_filter_menu_mob">
            <div className="br__filter_title_mob">
              <div className="br_filter_left_title_mob">
                <img src={ArrowTop} onClick={handleMobileFilterToggle} />
                <div>Filter</div>
              </div>
              <div
                className="br_filter_right_close"
                onClick={handleMobileFilterToggle}
              >
                <img className="close_filter" src={CloseIcon} />
              </div>
            </div>
            <div className="main_filter_mob">
              <div className="main_filter_mob_wrap">
                <ul className="main_filter_sub_menu">
                  {Object.keys(mobileFilterConfig).map((filterType, index) => (
                    <li
                      key={`${index}-${filterType}`}
                      className={`main_filter_sub_item ${
                        activeIndex === index ? "active_filter_mobile" : ""
                      }`}
                      onClick={() => handleItemClick(index)}
                    >
                      <div className="mfmc_wrap">
                        <div className="filter_category__name">
                          <div>{filterType}</div>
                          <img
                            src={ArrowTop}
                            className="leftArrow"
                            alt="Arrow"
                          />
                        </div>
                        <div className="filter_category_sub_menu">
                          <div className="fcsm_wrap">
                            <div className="top_category_back_name">
                              <img
                                src={ArrowTop}
                                className="leftArrow"
                                alt="Arrow"
                              />
                              <div>{filterType}</div>
                            </div>
                            <div className="cfsm_wrap">
                              <div className="cfsm_row">
                                <form action="Category_filter">
                                  <ul className="cfsm_row_list">
                                    {mobileFilterConfig[filterType]?.data?.map(
                                      mobileFilterConfig[filterType]?.render
                                    )}
                                  </ul>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="apply_filter_btns">
                <button className="cancel_fl filter_action_mob">Close</button>
                <button className="apply_fl filter_action_mob">Apply</button>
              </div>
            </div>
          </div>
        </div>

        <main className="brand__product-list">
          <div className="brand__product-list-header">
            <div className="brand__item_count">
              {/* <span className="brand__count"> {brandsData ? brandsData.length : 0} </span>items in */}
              <h1 className="brand__count__name">
                {/* {decodeURIComponent(selectedData.subCategory.toUpperCase() ||
                selectedData.categoryName.toUpperCase())} */}
                {decodeURIComponent(
                  location.pathname
                    .split("/")
                    .filter(Boolean)
                    .pop()
                    .replace(/\+/g, " ")
                )}
              </h1>
            </div>

          </div>
          {loading ? (
          <CustomLoader />
        ) : (
          <div
            className={`brand__product-grid grid__view ${productLength} ${
              !doesAPIHaveFilter ? "no_filter" : ""
            } ${isGridViewActive ? "grid__view" : "list__view"}`}
          >
            <>
              {!loading && brandsData?.length > 0 ?  (
                <>
                  {brandsData &&
                    brandsData.map(
                      (product) =>
                        product.Name && (
                          <ProductCard
                            key={product.id}
                            product={product}
                            setCountItems={setCountItems}
                            onAddtoCart={handleInitialAddToCart}
                            currentSelected={currentSelected}
                            isOutOfStock={
                              product?.VariantDetails?.[0]?.InStock == "no"
                            }
                            setIsToastMessage={setIsToastMessage}
                            from={"Particular_Product_Viewed"}
                            screenName={decodeURIComponent(
                              location.pathname
                                .split("/")
                                .filter(Boolean)
                                .pop()
                                .replace(/\+/g, " ")
                            )}
                          />
                        )
                    )}
                </>
              ) : (
                <>
                  <div className={`not_data_found_wrap ${productLength ? "hide": "active"}`}
                    style={{ width: "100%" }}
                  >
                    <div className="main_data_container">
                      <div className="img_found_wrap">
                        <img src={data_not_found} alt="img" />
                      </div>
                      <div className="not_found_text">
                        <h5>Oops.. No Data Found!</h5>
                      </div>
                    </div>
                  </div>
              </>
              ) }
            </>
          </div>
        )}
        </main>
        {isToastMessage && <ToastMessage />}
      </div>
    </>
  );
};

export default CategoryFilter;
