import { Link } from "react-router-dom";
import "./subCategoryMenu.css";
import { useNavigate } from 'react-router-dom';
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";

const SubCategoryMenu = ({
  selectedNavSubDetails,
  selectedNav,
  setCategoryChange,
  openCategory,categoryData
}) => {
  const navigate = useNavigate();

  let loaderComponent = () => {
    let loaderWithOpacity = document.querySelector(".opacity__main__container");
    let noMoreData = document.querySelector(".not_data_found_wrap")
    loaderWithOpacity.classList.add("active");
    setTimeout(() => {
      loaderWithOpacity.classList.remove("active");
    }, 1000);
  };

     // no data found //
     let noDataFound = () => {
        let noDataItem = document.querySelector(".not_data_found_wrap");
          noDataItem?.classList?.add("hide") 
    }

  const handleClick = (SubCAtegoryName, selectedNav,sub) => {
    let mainBody = document.querySelector("body");
    
    loaderComponent();
    noDataFound();
    navigate(`/category/${formatCategoryName(selectedNav).replace(/%20/g, "+")?.replace("%26","&")}/${formatCategoryName(sub.category_name)?.replace(/%20/g, "+")?.replace("%26","&")}?id=${sub?.id}`);
    setCategoryChange({
      selectedCategory: selectedNav,
      selectedSubCategory: SubCAtegoryName,
    });
    if (openCategory) {
      document.getElementById("menuDrawer").style.width = "0px";
    }
 
    moengage.track_event("HomePage_Main_Category_Viewed", {
      "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
      "Category Name": selectedNav,
      "Sub Category Name": SubCAtegoryName
    });  
  

  };

  // const formatCategoryName = (name) =>
  //   `${name}`
  //     .replace(/[^a-zA-Z0-9\s-]/g, "")
  //     .replace(/\s+/g, "-")
  //     .replace(/-$/, "")
  //     .replace(/--+/g, "-")
  //     .toLowerCase();
  const formatCategoryName = (name) =>
    encodeURIComponent(name)

  
  return !openCategory ? (
    <div className="nav_sub_menu">
      <ul>
        {selectedNavSubDetails.map((sub,index) => (
          <li key={sub.id}>
            <div
              className="nav_sub_list"
              id={sub.id}
              onClick={() => handleClick(sub.category_name, selectedNav,sub)}
            >
              {sub.category_name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <ul className="subCategoryMenu">
      {selectedNavSubDetails.map((sub, index) => (
        index < 8 ? (
          <li key={sub.id}>
            <Link
              className="nav_sub_list"
              id={sub.category_name}
              to={`/category/${formatCategoryName(selectedNav)?.replace(/%20/g, "+")?.replace("%26","&")}/${formatCategoryName(sub.category_name)?.replace(/%20/g, "+")?.replace("%26","&")}?id=${sub?.id}`}
              onClick={() => handleClick(sub.category_name, selectedNav)}
            >
              {sub.category_name}
            </Link>
          </li>
        ) : null
      ))}
    </ul>
  );
};

export default SubCategoryMenu;
