import React, { useEffect, useState } from "react";
import BagIcon from "../../assests/icons/Bag3.png";
import BuyIcon from "../../assests/icons/Buy.png";
import { useCart } from "../layout/cart/cartContext"; // Adjust path to your CartContext
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie } from "../../utils/cookieUtils"; // Adjust path to your cookieUtils
import {
  fetchRegisterId,
  fetchUserData,
  ADD_TO_CART,
  WEB_CARTLIST,
} from "../../Constants";
import Cookies from "js-cookie";
import addProductToCookies from "../layout/cart/addProductToCookies";
import fetchProductQuantityInCartList from "../../api/fetchProductQuantityInCartList";
import moengage from "@moengage/web-sdk";
const API_URL = ADD_TO_CART;
const WebCartList = WEB_CARTLIST;

const SubmitButtons = ({
  productId,
  variant1Value,
  variant2Value,
  quantity,
  product,
  setCountItems,
  setIsToastMessage,
  trackPageView,
  USIN
}) => {
  const { dispatch } = useCart();
  const [itemCount, setItemCount] = useState(0);
  const navigate = useNavigate();
 
  variant1Value = variant1Value ? variant1Value.replace(/-/g, " ").replace(/\b\w/g, char => char.toUpperCase()): "";
  variant2Value = variant2Value ? variant2Value.replace(/-/g, " ").replace(/\b\w/g, char => char.toUpperCase()): ""; 
//console.log("USINData",USIN)
  const handleCart = async (quantityy) => {
    let result , quantities = 4;
   
    
    if (fetchRegisterId()) {
      if (quantityy>0) {
        result = await fetchProductQuantityInCartList(
          product.Result[0].id || productId,
          variant1Value?.replace(/\+/g, " ") ||
            product.variant1Value?.replace(/\+/g, " "),
          variant2Value?.replace(/\+/g, " ") ||
            product.variant2Value?.replace(/\+/g, " "),
          quantity
        );

      
          let productDetails = product.Result[0];
          //console.log(productDetails);
          moengage.track_event(`Buy_Now`, {
            "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
            "Brand Name": productDetails.BrandName,
            "Product ID": productDetails.id,
            "Product Name": productDetails.Name,
            "Base Price": productDetails.PriceQuantity[0].MRP,
            "Promotion Price": productDetails.PriceQuantity[0].ListingPrice,
            "Quantity": quantityy,
            "Discount Percentage": parseInt( productDetails.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),
            [productDetails.PriceQuantity[0].Variant1Name ? productDetails.PriceQuantity[0].Variant1Name  :""] :productDetails.PriceQuantity[0].Variant1Value,
            ...(productDetails.PriceQuantity[0].Variant2Name  !== "" && {  [ productDetails.PriceQuantity[0].Variant2Name ] : productDetails.PriceQuantity[0].Variant2Value }),
            "USIN": USIN ,
            "Customer Id" : JSON.parse(Cookies.get("userData")).id ? JSON.parse(Cookies.get("userData")).id : JSON.parse(Cookies.get("resigterId")).RegisterId,       
          });

        
      } else {
        result = await fetchProductQuantityInCartList(
          product.Result[0].id || productId,
          variant1Value?.replace(/\+/g, " ") ||
            product.variant1Value?.replace(/\+/g, " "),
          variant2Value?.replace(/\+/g, " ") ||
            product.variant2Value?.replace(/\+/g, " ")
            
        );

        if(result){
          let productDetails = product.Result[0];
          //console.log(productDetails);
          moengage.track_event(`Add_to_Cart_Clicked`, {
            "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
            "Brand Name": productDetails.BrandName,
            "Product ID": productDetails.id,
            "Product Name": productDetails.Name,
            "Base Price": productDetails.PriceQuantity[0].MRP,
            "Promotion Price": productDetails.PriceQuantity[0].ListingPrice,
            "Discount Percentage":parseInt( productDetails.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
            [productDetails.PriceQuantity[0].Variant1Name ? productDetails.PriceQuantity[0].Variant1Name  :""] :productDetails.PriceQuantity[0].Variant1Value,
            ...(productDetails.PriceQuantity[0].Variant2Name  !== "" && {  [ productDetails.PriceQuantity[0].Variant2Name ] : productDetails.PriceQuantity[0].Variant2Value }),
            "USIN": USIN ,      
            "Screen Name": "Product Details Page ",
          });
        }
        


      }

      if (result) {
        await addToCart();
      }
    } else {
      // Handle scenario when user is not logged in
      const isNotLoggedProduct = product.Result[0].id;
      const isNotLoggedQuantity = document.querySelector(
        ".quantity__selector .input__value input"
      )?.value;
      if (isNotLoggedProduct && isNotLoggedQuantity) {
        let reqproduct = product?.Result[0];
        const requiredVariantData = reqproduct.PriceQuantity[0];
        if (
          addProductToCookies(
            reqproduct.Name,
            reqproduct.id,
            isNotLoggedQuantity,
            requiredVariantData?.ListingPrice || 0,
            requiredVariantData?.MRP || 0,
            requiredVariantData.Note || "",
            requiredVariantData?.pvid || null,
            variant1Value,
            variant2Value,
            requiredVariantData?.CoverImage?.[0].image
          )
        ) {
          moengage.track_event(`Add_to_Cart_Clicked`, {
            "ID": ``,
            "Brand Name": reqproduct.BrandName,
            "Product ID": reqproduct.id,
            "Product Name": reqproduct.Name,
            "Base Price": reqproduct.PriceQuantity[0].MRP,
            "Promotion Price": reqproduct.PriceQuantity[0].ListingPrice,
            "Discount Percentage":parseInt( reqproduct.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
            [reqproduct.PriceQuantity[0].Variant1Name ? reqproduct.PriceQuantity[0].Variant1Name  :""] :reqproduct.PriceQuantity[0].Variant1Value,
            ...(reqproduct.PriceQuantity[0].Variant2Name  !== "" && {  [ reqproduct.PriceQuantity[0].Variant2Name ] : reqproduct.PriceQuantity[0].Variant2Value }),
            "USIN": USIN ,      
            "Screen Name": "Product Details Page ",
          });
          setIsToastMessage(true);
          setTimeout(() => {
            setIsToastMessage(false);
          }, 3000);
        }
      }
      setCountItems((prev) => parseInt(prev) + 1);
    }
  };
  const addToCart = async () => {
    // if (fetchUserData()?.id) { // Check if user is logged in
    let RegisterId = fetchRegisterId();
    trackPageView();
    if (RegisterId) {
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: product.Result[0].id || productId,
            RegisterId,
            ProductQuantity: quantity,
            variant1_value:
              variant1Value?.replace(/\+/g, " ") ||
              product.variant1Value?.replace(/\+/g, " "),
            variant2_value:
              variant2Value?.replace(/\+/g, " ") ||
              product.variant2Value?.replace(/\+/g, " "),
              USIN:USIN
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to add product to cart! Status: ${response.status}`
          );
        }

        const result = await response.json();
        setIsToastMessage(true);
        setCountItems((prev) => parseInt(prev) + 1);
        console.log(result,"resultresult")
        // Dispatch action to update cart state
        dispatch({
          type: "ADD_TO_CART",
          payload: {
            id: productId,
            USIN:USIN,
            ...result, // If your API returns the updated cart item details
          },
        });

        // Ensure cart items are updated after adding

        // Notify user of successful addition
        // toast.success('Product added to cart successfully!');
      } catch (error) {
        console.error("Error adding product to cart:", error);
        // Notify user of error
        toast.error("Failed to add product to cart. Please try again.");
      } finally {
        setTimeout(() => {
          setIsToastMessage(false);
        }, 3000);
      }
    }
  };

  const handleBuyNowClick = async () => {
    try {
      await handleCart(quantity);
      // Navigate to the checkout page or desired page
      navigate("/cart");
    } catch (error) {
      console.error("Error processing Buy Now:", error);
    }
  };
  const productItem = product.Result[0];
  const inStock = productItem.PriceQuantity?.[0]?.InStock == "yes";

  return (
    <div
      className={`submit__buttons__container ${
        !inStock ? "outstock_item" : ""
      }`}
    >
      <div className="submit__buttons flex">
        <div
          className="btn_primary buy_now_btn"
          onClick={inStock ? handleBuyNowClick : null}
          disabled={!inStock}
        >
          <img src={BagIcon} alt="Buy Now" /> Buy Now
        </div>
        <div
          className="btn_primary add_to_cart"
          onClick={inStock ? handleCart : null}
          disabled={!inStock}
        >
          <img src={BuyIcon} alt="Add to Cart" /> Add To Cart
        </div>
      </div>
    </div>
  );
};

export default SubmitButtons;
