// src/components/navigation/Navigation.js
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import SubCategoryMenu from "../subCategoryMenu/subCategoryMenu";
import { ReactComponent as LeftArrow } from '../../../../assests/icons/arrowTop.svg';
import { WEB_CATEGORY_LIST, PARENT_CATEGORY_AND_SUBCATEGORYLIST } from "../../../../Constants";

import "./Navigation.css";


const Navigation = ({ setChangingCategory, openCategory ,setAnnouncementText}) => {

  const [navSubData, setNavSubData] = useState([]);
  const [parentCategoryList,setParentCategoryList] = useState()
  const [selectedNavSubDetails, setSelectedNavSubDetails] = useState([]);
  const [selectedNav, setSelectedNav] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [ categoryData ,setCategoryData] = useState([]);
  const [categoryChange, setCategoryChange] = useState({
    selectedCategory: "",
    selectedSubCategory: "",
  });

  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [isSticky, setIsSticky] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const fetchNavDetails = async () => {

      let payload = {
        groupCategoryId: 0,
        LevelType: 'shopDashboard'

      };

      try {
        const navResponse = await fetch(WEB_CATEGORY_LIST, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (!navResponse.ok) {
          throw new Error(`HTTP error! status: ${navResponse.status}`);
        }
        const navData = await navResponse.json();
        setParentCategoryList(navData)
        // setAnnouncementText(navData?.ShippingText)

        const subNavResponse = await fetch(PARENT_CATEGORY_AND_SUBCATEGORYLIST, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload).toString(),
        });

        if (!subNavResponse.ok) {
          throw new Error(`HTTP error! status: ${subNavResponse.status}`);
        }
        const subNavData = await subNavResponse.json();
        //console.log(subNavData)
        setNavSubData(subNavData.ParentCategory);

        // Set default selected category and subcategory details
        if (navData.Result.length > 0) {
          const defaultCategory = navData.Result[0].parent_category_name;
          setSelectedNav(defaultCategory);
          const defaultSubData = subNavData.ParentCategory.find(
            (sub) => sub.parent_category_name === defaultCategory
          );
          setSelectedNavSubDetails(defaultSubData ? defaultSubData.SubCategory : []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchNavDetails();
  }, []);

  useEffect(() => {
    setChangingCategory(categoryChange);
  }, [categoryChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setSelectedNav("");
        setActiveIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMouseEnter = (index, categoryName) => {
    setSelectedNav(categoryName);
    const data = navSubData.find((sub) => sub.parent_category_name === categoryName);
    setSelectedNavSubDetails(data ? data.SubCategory : []);
    setCategoryData(data);
  };

  const handleMouseLeave = () => {
    setSelectedNav("");
  };

  const handleClick = (index) => {
    setActiveIndex(index); // Update the active index
  };
  
  const mainMenuClick = (n) => {
    let categoryName = n.n.parent_category_name?.replace(/\s+/g, '+');
    navigate(`/category/${categoryName}?viewId=${n.n.id}`);
    window.scrollTo(0,0);
  };

  // console.log("gjgjgjg",selectedNavSubDetails)
  return (
    <nav className={`main-nav ${isSticky ? 'sticky' : ''}`}  ref={menuRef}>
      <div className="page-width">
        <ul className={`main__nav-container ${selectedNav && 'selected_subnav'}`} id="menu">
          {navSubData.map((n, index) => (
            <li
              key={n.id}
              // className={`${index === activeIndex ? "active" : ""}`}
              className={index === activeIndex ? 'active' : ''}
              onMouseEnter={() => handleMouseEnter(index, n.parent_category_name)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            >
              <div className="nav_menu_main">
                <div className="nav__menu">

                  <div className="nav__category_menu" onClick={()=> mainMenuClick({n})} data-categoryid={n.id}>{n.parent_category_name}</div>
                  <div className="left__arrow__mobile__header"><LeftArrow /></div>
                </div>
                {n.parent_category_name === selectedNav && (
                  <SubCategoryMenu
                    selectedNavSubDetails={selectedNavSubDetails}
                    selectedNav={selectedNav}
                    setCategoryChange={setCategoryChange}
                    openCategory={openCategory}
                    categoryData={categoryData}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
