import React, { useState, useEffect, useRef } from "react";
// import ProductCard from '../../layout/product/productCard/brandPlacementCard';
import "../../layout/product/productCard/productCard.css";
import Loader from "../../common/customLoader/customLoader";
// import { fetchProducts } from '../../../api/prodApi';
import { fetchBannerData } from "../../../api/HomePageApi";
// import { ReactComponent as LeftArrow } from '../../../assests/icons/LeftArrow.svg';
// import { Link } from 'react-router-dom';
// import "../category/categoryDetails.css"
import { fetchBestSellerData } from "../../../api/BestSellerApi";
import ProductCard from "../product/productCard/productCard";
import ToastMessage from "../../common/toastMessage/toastMessage";
import { useParams, useSearchParams } from 'react-router-dom';
const BestSellerPage = ({ categoryName, categoryId,setCountItems }) => {
  const [products, setProducts] = useState([]);
  const [isToastMessage, setIsToastMessage] = useState(false);  
  const [loading, setLoading] = useState(true); // State to track loading state
  const [page, setPage] = useState(1); // State for pagination
  const isMounted = useRef(false);
  const [hasMore, setHasMore] = useState(true);

  const [bestSellerProd, setBestSeller] = useState([]);
  const [bestSellerProdData, setBestSellerData] = useState([]);

  const [currentSelected, setCurrentSelected] = useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
   
  

  useEffect(() => {
    loadProducts(page);
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadMoreProducts = () => {
    if (hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMoreProducts();
          }
        });
      },
      { threshold: 0.1 } // A small threshold to trigger earlier
    );

    const observeLastProductCard = () => {
      const productCards = document.querySelectorAll(
        ".product-grid .product-card"
      );
      if (productCards.length === 0) return;
      const lastProductCard = productCards[productCards.length - 1];
      intersectionObserver.observe(lastProductCard);
    };

    if (!loading) {
      observeLastProductCard();
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [page, bestSellerProd, loading]);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      // const products = await fetchBestSellerData(categoryId, categoryName, page);
      const bestSellerProduct =
        (await fetchBestSellerData(categoryId, categoryName, page,id)) || [];


      setProducts((prevProducts) => [...prevProducts, ...bestSellerProduct]);
      setBestSeller((prevBestSeller) => [
        ...prevBestSeller,
        ...bestSellerProduct,
      ]);
      // setBestSeller([]);
      setBestSellerData(bestSellerProdData);

      setHasMore(bestSellerProduct.length === 10);

      setLoading(false);
    } catch (error) {
      debugger;
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // let categoryReplace = decodeURIComponent(categoryName);
  let categoryReplace = categoryName?.replace(/%20/g, "+");

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };
  return (
    <div className="hm__product-container hm__prod__slider best__seller__item page-width">
      <div className="page-widths">
        {/* <div className="hm__prod__slider-title">
          <h2>{'Best Seller'}</h2>
          
        </div> */}
      </div>

      <div className="grid_container cm_arrow_side">
        {/* {loading ? (
          <div className="loading-spinners">
            <div className='product-lists'>
              <Loader />
            </div>
          </div> // Display loader while fetching data
        ) : ( */}
        <div className="product-grid">
          {bestSellerProd.map((product, index) => (
            <ProductCard
              key={`${index}-${product.id}`}
              product={product}
              onAddtoCart={handleInitialAddToCart}
              currentSelected={currentSelected}
              note={product.PriceQuantity[0].Note}
              bestSellerProductData={bestSellerProdData}
              setIsToastMessage={setIsToastMessage}
              setCountItems={setCountItems}
              isOutOfStock={product?.PriceQuantity[0].InStock =="yes"? false : true}
              from={"Particular_Product_Viewed"}
              screenName={"BestSeller"}
            />
          ))}
        </div>
        {/* )}  */}
      </div>
      {isToastMessage && <ToastMessage />}
    </div>
  );
};

export default BestSellerPage;
