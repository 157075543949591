import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './megaMenu.css';
import SearchBar from '../../../layout/header/megaMenu/megaMenuSearch';
import { BrandList, fetchAllBrandsFromApi } from '../../../common/brands/brandApi';
import { ReactComponent as Arrow } from '../../../../assests/icons/arrowTop.svg';
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";
const MegaMenu = ({ active }) => {
  const [brands, setBrands] = useState(null);
  const [allBrands, setAllBrands] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [openMegamenu, setMegamenu] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchedBrands = await BrandList();
        setBrands(fetchedBrands);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching brands:', error);
        setLoading(false);
      }
    };

    if (!brands) {
      fetchBrands();
    }
  }, [brands]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchedAllBrands = await fetchAllBrandsFromApi();
        setAllBrands(fetchedAllBrands);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching brands:', error);
        setLoading(false);
      }
    };

    if (!allBrands) {
      fetchBrands();
    }
  }, [allBrands]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filterBrands = (brands) => {
    if (!searchQuery) {
      return brands;
    }

    const filtered = {};
    for (const [letter, brandList] of Object.entries(brands)) {
      const filteredList = brandList.filter((brand) =>
        brand.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredList.length > 0) {
        filtered[letter] = filteredList;
      }
    }
    return filtered;
  };

  if (loading || !brands) {
    return <div className="mega-menu">Loading...</div>;
  }


  const handleClick = (brand) => {
    let mainBody = document.querySelector("body");
    document.querySelector(".opacity__main__container").classList.add("active");
    
    // Scroll up by 220 pixels
  
    if (brand) {
  
        moengage.track_event("Brands_Viewed", {
          "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"",  
          "Brand Name": brand?.name, 
          "Screen Name" : "Brands from Header"
    
          });
        
      navigate(`/brands/${brand.name?.replace(/--+/g, "")?.replace(/[^a-zA-Z0-9 -]/g, "").replace(" ","-")?.replace(/\s+/g, "-").toLowerCase()}?id=${brand.id}`);
    }
  
    mainBody.classList.remove("minicart-active");
   
    // Delay removing the class and adjusting the menu
    setTimeout(() => {
      // // Ensure the scroll has taken effect before hiding the mega menu
      // const megaMenu = document.querySelector(".mega-menu");
      // if (megaMenu && !document.querySelector("#menuDrawer.is-open")) {
      //   megaMenu.classList.add("hide");
      // }
      document.querySelector(".opacity__main__container").classList.remove("active");
    }, 800);
  
    if (!openMegamenu) {
      document.getElementById("menuDrawer").style.width = "0px";
    }
  };
  

  const handle2Click = (brand) => {
    let mainBody = document.querySelector("body");
    document.querySelector(".opacity__main__container")?.classList.add("active");
    
    // Scroll up by 220 pixels
    // window.scrollBy(0, 220);
    if (brand) {
      console.log(brand?.BrandName)
  
        moengage.track_event("Brands_Viewed", {
          "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
          "Brand Name": brand?.BrandName, 
            "Screen Name" : "Top Brands from Header"
    
          });
        
      navigate(`/brands/${brand?.BrandName?.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-").toLowerCase()}?id=${brand?.BrandId}`);
    }
    mainBody.classList?.remove("minicart-active");
    setTimeout(() => {
      // const megaMenu = document?.querySelector(".mega-menu");
      // if (megaMenu && !document?.querySelector("#menuDrawer.is-open")) {
      //   megaMenu.classList.add("hide");
      // }
      document.querySelector(".opacity__main__container")?.classList.remove("active");
    }, 800);
    if (!openMegamenu) {
      document.getElementById("menuDrawer").style.width = "0px";
    }
  };

  const filteredBrands = filterBrands(brands);

  return (
    <div className={`mega-menu ${active ? 'is-active' : ''}`}>
      <div className="mega-menu-content">
        <div className="mega-menu-section left">
          <div className="mega__menu__search-bar">
            <SearchBar onSearch={handleSearch} />
          </div>
          <div className="main__mega__menu-content">
            {Object.keys(filteredBrands).length > 0 ? (
              Object.keys(filteredBrands).map((letter) => (
                <React.Fragment key={letter}>
                  <span>{letter}</span>
                  <ul>
                    {filteredBrands[letter].map((brand, index) => (
                      <li key={`${letter}-${index}`}>
                        <a role='button' 
                          className="brand__name" 
                          onClick={() => handleClick(brand)}
                        >
                          {brand.name} <Arrow />
                        </a>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))
            ) : (
              <div>No brands found</div>
            )}
          </div>
        </div>
        <div className="mega-menu-section right">
          <div>Top Brands</div>
          <ul className="top__brands">
            {allBrands && Object.values(allBrands).flat().map((brand, index) => (
              <li key={index} className="top__brands__item">
                <a role='button' 
                  onClick={() => handle2Click(brand)}
                >
                  <img src={brand.BrandLogo} alt={brand.BrandName} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
