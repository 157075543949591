import React from "react";
import { useMediaQuery } from "react-responsive";
import ImageComponent from "../image/mainImageComp";

const BrandBannerImages = [
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 1",
  },
];

const BrandBanner = ({ brandDetails }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  // Default to an empty object if brandDetails is not provided
  return (
    <div className="offer__banner__wrappe padding-top">
      <div className="offer__banner__container brand__details page-width">
        <div className="offer__banner__slider__gap">

            <div >
              {isMobile && brandDetails?.BrandImage && 
                <ImageComponent
                  src={brandDetails?.BrandImage}
                  alt={brandDetails?.BrandName}
                  height={"320px"}
                  pageWidth="100%" // Adjust as per your requirement
                />}
             
             

              
           
           {isDesktop && brandDetails?.WebBrandImage  && <ImageComponent
                  src={brandDetails?.WebBrandImage}
                  alt={brandDetails?.BrandName}
                  height={"100%"}
                  pageWidth="1200" // Adjust as per your requirement
                /> }
              
            </div>
      
        </div>
      </div>
    </div>
  );
};

export default BrandBanner;
