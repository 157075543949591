import React, {  useState } from 'react';
import './brandFilter.css';
import ProductCard from '../../layout/product/productCard/productCard';
import Loader from '../loader/loadingCard';
import { data_not_found} from '../../../utils/image';
import ToastMessage from '../toastMessage/toastMessage';
import HomeIconsItem from '../homeIcons/hmIconsGrid';

const BrandFilter = ({ BrandProductsListData,loading ,setCountItems,brandDetails,whyChooseUnfitImg}) => {
  const [currentSelected, setCurrentSelected] = useState([]);

  const [isToastMessage, setIsToastMessage] = useState(false);  




  const IconsContainer = [
    { 
      src: '/images/1.png',
      text: '100% original',
      alt: 'New Image 1' 
    },
    { 
      src: '/images/2.png',
      text: 'Certified by Brands',
      alt: 'New Image 2' 
    },
    { 
      src: '/images/3.png',
      text: 'Secure Packing',
      alt: 'New Image 3' 
    },
    { 
      src: '/images/4.png',
      text: 'Fast Delivery',
      alt: 'New Image 3' 
    }
  ];

  const handleInititalAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };

  return (
    <>
  { whyChooseUnfitImg && <HomeIconsItem data={whyChooseUnfitImg} />}
  <br/>
  <br/>
    <div className="brand__filter__wrapper page-width">
  
            
      <main className="brand__product-list">
        <div className={`brand__product-grid grid__view `}>
          {loading ? (
            <div className="loading-spinner">
              {/* Display loader while fetching data */}
              {[...Array(5)].map((_, index) => (
                <Loader key={index} />
              ))}
            </div>
          ) : BrandProductsListData ? (
            BrandProductsListData.map(product => (
              product.Type === 'product' && (
                <ProductCard key={product.id} product={product} 
                isOutOfStock={product?.VariantDetails?.[0]?.InStock === "no"}
                onAddtoCart={handleInititalAddToCart}
                currentSelected={currentSelected}
                setIsToastMessage={setIsToastMessage}
                setCountItems={setCountItems}
                from={"Particular_Product_Viewed"}
                screenName={brandDetails?.name}
                />
              )
            ))
          ) : (
            <>
              <div className="not_data_found_wrap" style={{ width: "100%" }}>
                <div className="main_data_container">
                  <div className="img_found_wrap">
                    <img src={data_not_found} alt="img" />
                  </div>
                  <div className="not_found_text">
                    <h5>Oops.. No Data Found!</h5>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* <div className="loadmore-btn">Load More</div> */}
      </main>
      {isToastMessage && <ToastMessage />}
    </div>
    </>
  );
};

export default BrandFilter;
