// src/api/ProductListApi.js
import { BEST_SELLER } from "../Constants";
const BASE_URL = BEST_SELLER;

// Caching and promise management
let cachedBestSellerData = null;
let fetchBestSellerProd = null;

// Fetch Blog List Data
export const fetchBestSellerData = async (categoryId, categoryName, page,id) => {
  // if (cachedBestSellerData) {
  //   return cachedBestSellerData;
  // }

  // if (fetchBestSellerProd) {
  //   return fetchBestSellerProd;
  // }
  fetchBestSellerProd = new Promise(async (resolve, reject) => {
    try {
      const payload = new URLSearchParams({
        Pagination: page,
        groupCategoryId: '',
        LevelType: '',
        panelId:id ? id : ""
      });

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      cachedBestSellerData = data.Result[0] || [];
      resolve(cachedBestSellerData);
    } catch (error) {
      reject(error);
    } finally {
      // fetchBestSellerProd = null;
    }
  });

  return fetchBestSellerProd;
};
