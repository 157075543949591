// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/layout/header/Header';
import AnnoucementBar from './components/layout/header/announcementBar/announcementBar';
import Navigation from './components/layout/header/navigation/Navigation';
import Search from './components/common/searchBar/searchBar';
import Footer from './components/layout/footer/footer';
import { CartProvider } from './components/layout/cart/cartContext';
import AppRoutes from './routes/routes';
import Register from "./components/layout/customer/register/register";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moengage from "@moengage/web-sdk";
import Appannouce from "./components/layout/header/appannounce/Appannouce";
//import CustomLoader from "./components/common/loader/loaderWithOpacity";
import BackToTopBtn from "./components/common/backToTopbtn";



function App() {
  const [changingCategory, setChangingCategory] = useState("");
  const [countItems, setCountItems] = useState(0);
  const [displayProductList, setDisplayProductList] = useState([]);
  const [announcementText,setAnnouncementText] = useState("")
 // const [isActive,setIsActive] = useState(false);

 // or, using `require`
 //const moengage = require("@moengage/web-sdk");
 
 moengage.initialize({app_id: '860XK1UT535ICU9IA2KTWU43', cluster: 'dc_3'});
//  moengage.add_unique_user_id('+918603743874');
//  moengage.add_first_name("Shivangi");
//  moengage.add_mobile('+918603743874')
 
  
  return (
    <CartProvider>
      <Router>
        <ToastContainer />

        <div className="main__container">
          <div className="opacity__main__container">
            {/* {isActive && <CustomLoader />} */}
          </div>

          {/* Conditionally render Header and Footer */}
          <Routes>
            <Route
              path="/*"
              element={
                <>
                  <Appannouce setChangingCategory={setChangingCategory}/>
                  <Header countItems={countItems} setCountItems={setCountItems} setChangingCategory={setChangingCategory} setDisplayProductList={setDisplayProductList} />
                  <Navigation setChangingCategory={setChangingCategory} setDisplayProductList={setDisplayProductList} setAnnouncementText={setAnnouncementText} />
                  <Search setDisplayProductList={setDisplayProductList} />
                  <AnnoucementBar announcementText={announcementText}/>
                  <AppRoutes setCountItems={setCountItems} changingCategory={changingCategory} displayProductList={displayProductList}  />
                  <Footer />
                  <BackToTopBtn />
                </>
              }
            />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/register" element={<Register />} />
            {/* <Route path="/checkout" element={<Checkout />}/> */}
          </Routes>
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
