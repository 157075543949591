import React, { useState, useEffect } from "react";
import { fetchBannerData,fetchDashBoardData } from "../../../../api/HomePageApi";
import { Slide } from "react-toastify";
import Slider from "react-slick";
import { WEB_CATEGORY_LIST, PARENT_CATEGORY_AND_SUBCATEGORYLIST } from "../../../../Constants";


const AnnoucementBar = ({announcementText,setChangingCategory}) => {
  const [announcementData, setAnnouncementText] = useState([]);
  

  useEffect(() => {
    const fetchNavDetails = async () => {
      let payload = {
        groupCategoryId: 0,
        LevelType: 'shopDashboard'
      };

      try {
        const navResponse = await fetch(WEB_CATEGORY_LIST, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (!navResponse.ok) {
          throw new Error(`HTTP error! status: ${navResponse.status}`);
        }
        const navData = await navResponse.json();
        setAnnouncementText(navData?.ShippingText)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchNavDetails();
  }, []);

  
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="header__announcement-bar">
      <div className="header__announcement-container">
        <div className="header__announcement-wrapper ticker-containe page-width">
          <div className="announcement__content mobile ticker-1"><span>{announcementData}</span></div>
          <div className="announcement__content desktop"> {announcementData} </div>
      </div>
      </div>
    </div>
  );
};

export default AnnoucementBar;