
import Cookies from 'js-cookie';
const hostname = window.location.origin;
const isAdmin = hostname.includes('https://unfitmart.com');
const isStageAdmin = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://stage.unfitmart.com';
const URL = isAdmin ? "admin.unfit.co" : "stage-admin.unfit.co";
export const STAGE_ADMIN = isStageAdmin.includes("localhost") ? true : false || isStageAdmin.includes("stage") ? true : false ;
export const DASHBOARD = `https://${URL}/api/WebDynamicDashboard`; 
export const WEB_CARTLIST = `https://${URL}/api/CartList`; // Order_id
export const WEB_ADDRESS = `https://${URL}/api/WebAddress`;
export const WEB_CART_DELETE = `https://${URL}/api/WebCartDelete`;
export const WEB_CART_QUANTITY_UPDATE = `https://${URL}/api/CartQuantityUpdate`;
export const WEB_ORDER_HISTORY = `https://${URL}/api/WebOrderList`;
export const WEB_EDIT_PROFILE = `https://${URL}/api/WebEditProfile`;
// export const BRAND_LIST = `https://${URL}/api/BrandList`;
// export const CHECK_USER_NEW = `https://${URL}/api/CheckUserNew`;
export const PRODUCTLIST = `https://${URL}/api/WebProductList`;  //
export const WEBPRODUCTLIST = `https://${URL}/api/ProductList`;  //
// export const WEBHOMEPAGE = `https://${URL}/api/WebHomePage`;
export const UNDERMAINTENANCE = `https://${URL}/api/underMaintenance`;

export const PRODUCTDETAIL = `https://${URL}/api/WebProductDetails`;
export const WEB_PRODUCT_LIST = `https://${URL}/api/WebProductList`;
export const PRODUCTLIST_BY_CATEGORY = `https://${URL}/api/ProductListByCategory`;
export const WEB_CATEGORY_LIST = `https://${URL}/api/WebcategoryList`;
export const PARENT_CATEGORY_AND_SUBCATEGORYLIST = `https://${URL}/api/parentAndSubCategoryList`;
export const FILTER_LIST = `https://${URL}/api/FilterList`;
export const Product_Rating = `https://${URL}/api/ProductRating`;
export const PRODUCT_RATING_LIST = `https://${URL}/api/ProductRatingList`;

// Home and Footer Api //
export const FOOTER_API = `https://${URL}/api/WebFooter`;
export const WEBHOMEPAGE = `https://${URL}/api/WebHomePage`;
export const BEST_SELLER = `https://${URL}/api/bestSellingProductList`;

// Blog Post //
export const BLOGPOST = `https://${URL}/api/blogPost`;
export const RECOMMENDEDBLOG = `https://${URL}/api/recommendedBlog`; 
export const BLOGDETAIL = `https://${URL}/api/blogPostDetails`;
export const BLOGCATEGORY = `https://${URL}/api/blogCategory`;

// Cart Api //
export const UPDATE_CART_QUANTITY = `https://${URL}/api/UpdateCartQuantity`;
export const ADD_TO_CART = `https://${URL}/api/AddToCartProduct`;

// Order Api //
export const CANCEL_ORDER = `https://${URL}/api/OrderCancel`
export const ORDER_DETAIL = `https://${URL}/api/OrderDetail`;

// Login Api
export const LOGIN_NEW = `https://${URL}/api/LoginNew`;
export const CHECK_USER_NEW = `https://${URL}/api/CheckUserNew`;

// Promocode Api //
export const PROMO_CODE = `https://${URL}/api/promoCodeList`;

// Brand Api
export const BRAND_LIST = `https://${URL}/api/BrandList`;
export const BRAND_DETAILS = `https://${URL}/api/BrandDetails`;

// search Api //
export const SEARCH_API = `https://${URL}/api/DashboardSearch`;
export const SEARCH_DETAIL = `https://${URL}/api/DashboardSearchDetail`;

// place order api //
export const WEB_PLACE_ORDER = `https://${URL}/api/WebPlaceOrder`;
export const WEB_PLACE_ORDER_STATUS = `https://${URL}/api/WebPlaceOrderStatus`;
export const WEB_ORDER = `https://${URL}/api/WebOrder`;

//wishList API
export const ADD_TO_WISH_LIST = `https://${URL}/api/wishList`;
export const WISH_LIST_PRODUCTLIST = `https://${URL}/api/wishListProduct`;


// PDF generator //
export const PDF_GENERATE = `https://${URL}/generateInvoicePdf`; // URL of the invoice file

// Meta Tag Api //
export const META_DATA = `https://${URL}/api/MetaData`;


export const fetchRegisterId = () => {
   let registerId = Cookies.get('resigterId');
   if (registerId) {
      return JSON.parse(registerId).RegisterId
   }
   else if (fetchUserData()) {
   // if (fetchUserData()) {
      return fetchUserData().id
   }
   else return null
}
export const fetchSessionIdId = () => {
   let sessionId = Cookies.get('sessionId');
   if (sessionId) {
      return sessionId
   }
}
export const fetchUserData = () => {
   let userData = Cookies.get('userData');
   if (userData) {
      
      return JSON.parse(userData)
   }
}
export const fetchUserAddress = () => {
   let userAddress = Cookies.get('userAddress');
   if (userAddress) {
     
      return JSON.parse(userAddress)
   }
}

export const replaceReplacementChar = (text) => {
   return text.replace(/\ufffd/g, ' '); // Replace with a hyphen or choose a different replacement
 };