import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as BagIcon } from "../../../../assests/icons/cartIconBlack.svg";
import { ReactComponent as AccountIcon } from "../../../../assests/icons/Profile.svg";
import { ReactComponent as Home } from "../../../../assests/icons/home.svg";
import { ReactComponent as OrderIcon } from "../../../../assests/icons/accountCart.svg";
import { ReactComponent as LogOutIcon } from "../../../../assests/icons/logout.svg";
import { ReactComponent as WishlistIcon } from "../../../../assests/icons/HeartOutLine.svg";
import Cookies from "js-cookie"; // Import js-cookie
import moengage from "@moengage/web-sdk";
import Login from "../../../layout/customer/login/login";
import {
  fetchRegisterId,
  fetchUserData,
  PROMO_CODE,
  WEB_ADDRESS,
  WEB_CARTLIST,
} from "../../../../Constants";
import Account from "../../customer/account/account";
import UpdateAddress from "../../customer/account/address";
import { getCookie } from "../../../../utils/cookieUtils";
const HeaderIcons = ({ countItems, setCountItems }) => {
  const [activeIcon, setActiveIcon] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const accountMenuRef = useRef(null);
  const [items, setItems] = useState(0);
  const [addres, setAddress] = useState(false);
  const [displayLoginPages, setDisplayLoginPages] = useState({
    displayLogin: false,
    displayAccountPage: false,
    displayAddressPage: false,
    accountVerified: false,
  });
  const isInitialRenderCountItems = useRef(true);

  useEffect(() => {
    if (addres) {
      updateAddress();
    }
  }, [addres]);
  useEffect(() => {
    setActiveIcon(null);
  }, [location]);




  const fetchCartItems = async () => {
    try {
     
      const RegisterId = fetchRegisterId();
    
      let response;
     // let registerID;

     // registerID = RegisterId; //JSON.parse(Cookies.get("resigterId")).RegisterId;
      response = await fetch(WEB_CARTLIST, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: RegisterId
          , PaymentType: 'Prepaid', ApplicationType: 'web'
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const cartItemsData = await response.json();
      let itemCount=0; 
       cartItemsData.Result.map(item =>{
        itemCount = itemCount+parseInt(item.ProductQuantity);
       });

    //  console.log(itemCount);
      setItems(itemCount);
    
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateAddress = () => {
    const addAddress = async () => {
      try {
        //let registerID;

        const response = await fetch(WEB_ADDRESS, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            RegisterId: fetchRegisterId(),
            HouseNumber: addres.building,
            Street: addres.street,
            LandMark: addres.landmark,
            Country: addres.country,
            State: addres.state,
            City: addres.city,
            PostalCode: addres.pincode,
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch cart items! Status: ${response.status}`
          );
        }

       // const updatedAddress = await response.json();
        fetchAddress();
      } catch (error) {
        // setSessionError(error.message);
      }
    };
    addAddress();
  };

  useEffect(() => {
    if (isInitialRenderCountItems.current) {
      // On initial render, just set the ref to false
      isInitialRenderCountItems.current = false;
      return;
    }
    if(fetchRegisterId())
    {
      fetchCartItems();
    }
    else{
      const cartItemsData = getCookie("cartData");
        //setCountItem(cartItemsData?.Result);
        // setItems(cartItemsData || {});
        let totalQuantity=0
        //console.log(cartItemsData)
        if(cartItemsData){
          JSON.parse(cartItemsData).map(items => {
            totalQuantity=totalQuantity+parseInt(items.quantity);
          })
          // console.log(totalQuantity)
          setItems(totalQuantity)
         setCountItems(totalQuantity);
}
    }
   

    const handleClickOutside = (event) => {
      if (
        accountMenuRef.current &&
        !accountMenuRef.current.contains(event.target)
      ) {
        setActiveIcon(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [countItems]);

  const handleIconClick = (icon) => {
    setActiveIcon((prevIcon) => (prevIcon === icon ? null : icon));
  };

  const userDataString = Cookies.get("userData");
  let userDataObj = null;

  if (userDataString) {
    userDataObj = fetchUserData(); //JSON.parse(userDataString);

    if (userDataObj.userName) {
      userDataObj.userName = userDataObj.userName.split(" ")[0];
    }
  }

  let modal = document.getElementById("myModal");

  const handleLogin = () => {
    setDisplayLoginPages({ ...displayLoginPages, displayLogin: true }); // Show login modal
    modal.style.display = "block";
  };



  const handleLogout = () => {
    moengage.destroy_session();
    Cookies.remove("sessionId"); // Remove sessionId from cookies
    Cookies.remove("userData"); // Remove userData from cookies
    Cookies.remove("resigterId");
    Cookies.remove("Otp");
    Cookies.remove("userAddress");
    navigate("/login"); // Navigate to login route
  };

  const fetchAddress = () => {
    const fetchExistingAddress = async () => {
      try {
        let registerID = fetchRegisterId();
    
        if (fetchUserData()) {
          const response = await fetch(WEB_ADDRESS, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              RegisterId: registerID,
           
            }),
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch address! Status: ${response.status}`
            );
          }

         // const fetchedAddress = await response.json();
         // const existingAddress = fetchedAddress.Result[0];
          // setDisplayAddress({
          //   houseNumberAndStreet: existingAddress.HouseNumberAndStreet,
          //   landmark: existingAddress.Landmark,
          //   pincode: existingAddress.PostalCode,
          //   state: existingAddress.State,
          //   city: existingAddress.City,
          //   country: existingAddress.Country,
          // });
        }
      } catch (error) {
        // setSessionError(error.message);
      }
    };
    const fetchPromoCode = async () => {
      try {
        const RegisterId = fetchRegisterId();
        const payload = new URLSearchParams({
          RegisterId,
        });
        const response = await fetch(PROMO_CODE, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }

        //const data = await response.json();
        // setPromoCode(data.Result);
      } catch (err) {
        // setError(err.message);
      } finally {
      }
    };
    fetchPromoCode();
    fetchExistingAddress();
  };

  return (

    <>
      <div className="header__icons_inner">
        <div className="header__icon-link">
          <div
            className={`header__account main__account__menu__item ${activeIcon === "account" ? "active" : ""
              }`}
            onClick={() => handleIconClick("account")}
            ref={accountMenuRef}
          >
            <div className="user__name">
              {fetchUserData()?.firstName || fetchUserData()?.userName.split(' ')[0]} <AccountIcon />
            </div>

            {userDataObj ? (
              <div
                className={`account__menu ${activeIcon === "account" ? "active" : ""
                  }`}
              >
                <Link to="/account" className="account__submenu account-info">
                  <AccountIcon />
                  Account Info
                </Link>
                <Link
                  to="/account/address"
                  className="account__submenu account-info"
                >
                  <Home />
                  My Address
                </Link>
                <Link
                  to="/account/orders"
                  className="account__submenu account-info"
                >
                  <OrderIcon />
                  Orders
                </Link>
                {/* <Link
                  to="/account/wishlist"
                  className="account__submenu account-info"
                 
                >
                  <WishlistIcon />
                  WishList
                </Link> */}
                <Link
                  to="/"
                  className="account__submenu account-info"
                  onClick={handleLogout}
                >
                  <LogOutIcon />
                  Logout
                </Link>
              </div>
            ) : (
              <div
                className={`account__menu ${activeIcon === "account" ? "active" : ""
                  }`}
              >
                <Link
                  to="/"
                  className="account__submenu account-info"
                  onClick={handleLogin}
                >
                  <LogOutIcon />
                  Login / Register
                </Link>
                {/* <div
                className="account__submenu account-info"
                onClick={handleRegister}
              >
                <LogOutIcon />
                Register
              </div> */}
              </div>
            )}
          </div>
        </div>
        <div className="header__icon-link">
          <div className="header__bag" onClick={() => handleIconClick("bag")}>
            <Link to="/cart">
              <BagIcon />
              <div className="itemsCount">{items || 0}</div>
            </Link>
          </div>
        </div>

        {/* Uncomment if you want to use a modal */}

      </div>
      <div id="myModal" className="modal">
        {displayLoginPages.displayLogin && (
          <Login
            setDisplayLoginPages={setDisplayLoginPages}
            displayLoginPages={displayLoginPages}
          />
        )}

        {displayLoginPages.displayAccountPage &&
          !displayLoginPages.displayLogin && (
            <div className="modalWrapper">
              <div className="modalContent">
                <Account
                  displayLoginPages={displayLoginPages}
                  setDisplayLoginPages={setDisplayLoginPages}
                  fetchAddress={fetchAddress}
                />
              </div>
            </div>
          )}

        {displayLoginPages.displayAddressPage &&
          displayLoginPages.accountVerified && (
            <div className="modalWrapper">
              <div className="modalContent">
                <UpdateAddress
                  displayLoginPages={displayLoginPages}
                  setDisplayLoginPages={setDisplayLoginPages}
                  setAddress={setAddress}
                />
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default HeaderIcons;
