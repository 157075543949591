import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { fetchMetadata } from '../../../api/MetaTagApi';
import { useLocation, useNavigate } from 'react-router-dom';

const MetaTagsComponent = () => {
    const [metaData, setMetaData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate
    const liveUrl = "https://unfitmart.com";
    const urlMeta =  window.location.href;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
     
        const fetchMetadataApi = async () => {
            try {
                const metaApi = await fetchMetadata();
                const pathname = location.pathname;               
                // ----------------------- Home -----------------------//
                if (pathname === '/') {
                    setMetaData(metaApi?.Result?.find(item => item.WebPageName === 'Home') || {});
                // ----------------------- Brand Page -----------------------//
                } else if (pathname.includes("brands")) {
                    const matchingItems = metaApi?.Result?.filter(item => {
                        const targetUrl = urlMeta.replace('http://localhost:3000', 'https://stage.unfitmart.com');
                        return item.WebPageURL === liveUrl ? item.WebPageURL === urlMeta : item.WebPageURL === targetUrl;
                    });
                    
                   setMetaData(matchingItems[0] || {})

                //----------------------- Product page ------------------------ //
                } else if (pathname.includes("product")) {
                    const matchingItems = metaApi?.Result?.filter(item => {
                        const targetUrl = urlMeta.replace('http://localhost:3000', 'https://stage.unfitmart.com');
                        return item.WebPageURL === liveUrl ? item.WebPageURL === urlMeta : item.WebPageURL === targetUrl;
                    });
                   setMetaData(matchingItems[0] || {})
                } 

                //----------------------- Contact US --------------------------- //
                else if (pathname.includes("contact")) {
                    setMetaData(metaApi?.Result.find(item => item.WebPageName === 'Contact us') || {});

                 //-----------------------  Shipping Policy ----------------------- // 
                }else if(pathname.includes("shipping-policy")) {
                    setMetaData(metaApi?.Result.find(item => item.WebPageName === 'Shipping Policy') || {});

                //------------------------ Terms condition ------------------------- //
                }else if(pathname.includes("terms-condition")) {
                    setMetaData(metaApi?.Result.find(item => item.WebPageName === 'Terms and Conditions') || {});

                //------------------------ Service refund Policy -------------------- //
                }else if(pathname.includes("terms-condition")) {
                    setMetaData(metaApi?.Result.find(item => item.WebPageName === 'Terms and Conditions') || {});

                //------------------------ Privacy Policy --------------------------- //
                }else if(pathname.includes("privacy-policy")) {
                    setMetaData(metaApi?.Result.find(item => item.WebPageName === 'Privacy Policy ') || {});

                //------------------------ Refund Policy ---------------------------- //
                }else if (pathname.includes("refund-policy")) {
                let mainPPData = metaApi?.Result?.find(item => item.WebPageName === "Refund Policy");
                setMetaData(mainPPData || {});

                //------------------------ Category --------------------------------- //
                }else if (pathname.includes("category")) {
                    const matchingItems = metaApi?.Result?.filter(item => {
                        const targetUrl = urlMeta.replace('http://localhost:3000', 'https://stage.unfitmart.com');
                        return item.WebPageURL === liveUrl ? item.WebPageURL === urlMeta : item.WebPageURL === targetUrl;
                    });
                    setMetaData(matchingItems[0] || {});

                } else if (pathname.includes("blogs")) {
                    setMetaData(metaApi?.Result[0] || {});
                } else if (pathname.includes("blog")) {
                    let postId = params.get('post_id');
                    const targetUrl = urlMeta.replace('http://localhost:3000', 'https://unfitmart.com');

                    const matchingItems = metaApi?.Result?.filter(item => {
                        if(item.WebPageURL.includes(targetUrl)){
                            return item.WebPageURL;
                        }
                    });

                    setMetaData(matchingItems[0] || {});
                } else {
                    setMetaData(null);
                }
              
            } catch (error) {
                console.error('Error fetching metadata:', error);
                setMetaData(null); 
            }
        };

        
        fetchMetadataApi();
    }, [location, navigate]); 

     
    if (!metaData) return null;

    // Define meta tags based on fetched metadata
    const metaTags = {
        title: metaData.MetaTitle || "Default Title",
        description: metaData.MetaDescription || "Default Description",
        keywords: metaData.MetaKeywords || "Default Keywords",
        'og:title': metaData.MetaTitle || "Default OG Title",
        'og:description': metaData.MetaDescription || "Default OG Description",
        'og:image': metaData.MetaImage || "%PUBLIC_URL%/fav.jpg"
    };

    return (
        <Helmet>
            <title>{metaTags.title}</title>
            <meta name="description" content={metaTags.description} />
            <meta name="keywords" content={metaTags.keywords} />
            <meta property="og:title" content={metaTags['og:title']} />
            <meta property="og:description" content={metaTags['og:description']} />
            <meta property="og:image" content={metaTags['og:image']} />
            <link rel="canonical" href={`${window.location.href}`} />
            {/* Add any other meta tags here */}
        </Helmet>
    );
};

export default MetaTagsComponent;
