import React, { useState, useEffect } from "react";
import "./productCard.css";
import { Link } from "react-router-dom";
// import GreyHeartIcon from "../../../../assests/icons/GreyHeart.svg"
// import HeartIcon from "../../../../assests/icons/Heart.svg"
import CartIcon from "../../../../assests/icons/cartIcon.svg";
import { useCart } from "../../cart/cartContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { ADD_TO_CART, fetchRegisterId } from "../../../../Constants";
import Cookies from "js-cookie";
import { Close_Square } from "../../../../utils/image";
import Select from "react-select";
import addProductToCookies from "../../cart/addProductToCookies";
import fetchProductQuantityInCartList from "../../../../api/fetchProductQuantityInCartList";
// import addWishList from "../../../../api/addWishList";
 import moengage from "@moengage/web-sdk";

const ProductCard = ({
  from,
  screenName,
  product,
  setCountItems,
  brandPlacementProduct,
  onAddtoCart,
  currentSelected = [],
  isOutOfStock = false,
  setIsToastMessage = () => {},
}) => {
  //const VariantData = product.VariantData;


  const [wishListStatus, setWishListStatus] = useState(0);
  const [variant1Value, setVariant1Value] = useState(null);
  const [variant2Value, setVariant2Value] = useState(null);
  const [activePopup , setActivePop] = useState(null);
  const [variant1Values,setVariant1Values] = useState([]);
  const [variant2Values,setVariant2Values] = useState([]);
  const [selectedVariant1, setSelectedVariant1] = useState(null);
  const [selectedVariant2, setSelectedVariant2] = useState(null);
  const [USIN, setUSINData] = useState(
    product?.VariantData && product.VariantData[0] && product.VariantData[0].USIN || product?.PriceQuantity && product.PriceQuantity[0] && product.PriceQuantity[0].USIN
  );
  const [variantPvid, setPvid] = useState(
    product?.VariantData && product?.VariantData.length > 0 ? product?.VariantData[0]?.pvid : null
  );


  
  const { dispatch } = useCart();
  // const navigate = useNavigate();

  const addToCart = async () => {
    try {
      setIsToastMessage(true);
  
      let RegisterId = fetchRegisterId();

  
      // if(RegisterId){

      const response = await fetch(ADD_TO_CART, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: product.id || "",
          RegisterId,
          ProductQuantity: 1,
          variant1_value: selectedVariant1?.value,
          variant2_value: selectedVariant2?.value,
          USIN:USINData // Adjust as needed, depending on how quantity is managed
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to add product to cart! Status: ${response.status}`
        );
      }

      dispatch({
        type: "ADD_TO_CART",
        payload: {
          id: product.id,
          title: product.title,
          price: product.price,
          quantity: 1,
          image: product.image,
        },
      });

      // toast.success("Product successfully added to cart!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error("Error adding product to cart");
    } finally {
      setTimeout(() => {
        setIsToastMessage(false);
      }, 3000);
      onAddtoCart({ id: product.id });
    }
  };


const pageWidth = 1200;

  
  let USINData = product?.VariantData && product.VariantData[0] && product.VariantData[0].USIN || product?.PriceQuantity && product.PriceQuantity[0] && product.PriceQuantity[0].USIN;
  let image =
    product.VariantDetails &&
    product.VariantDetails[0] &&
    product.VariantDetails[0].CoverImage &&
    product.VariantDetails[0].CoverImage[0] &&
    product.VariantDetails[0].CoverImage[0].image;
  let image2 =
    product.PriceQuantity &&
    product.PriceQuantity[0] &&
    product.PriceQuantity[0].CoverImage &&
    product.PriceQuantity[0].CoverImage[0] &&
    product.PriceQuantity[0].CoverImage[0].image;

  let comparePrice =
    (product.VariantDetails &&
      product.VariantDetails[0] &&
      product.VariantDetails[0].Mrp) ||
    product?.PriceQuantity?.[0]?.Mrp;

  let comparePrice2 =
    product.PriceQuantity &&
    product.PriceQuantity[0] &&
    product.PriceQuantity[0].Mrp;

  let price =
    (product.VariantDetails &&
      product.VariantDetails[0] &&
      product.VariantDetails[0].Price) ||
    product?.PriceQuantity?.[0]?.Price;

  if (comparePrice && comparePrice.endsWith(".00")) {
    comparePrice = comparePrice.slice(0, -3);
  }
  if (comparePrice2 && comparePrice2.endsWith(".00")) {
    comparePrice2 = comparePrice2.slice(0, -3);
  }

  if (price && price.endsWith(".00")) {
    price = price.slice(0, -3);
  }

  let currentProductId;
  let discount =
    (product.VariantDetails &&
      product.VariantDetails[0] &&
      product.VariantDetails[0]?.Note) ||
    "";
  discount = discount ? discount.substring(1, discount.length - 1) : "";
  let discount2 =
    (product.PriceQuantity &&
      product.PriceQuantity[0] &&
      product.PriceQuantity[0]?.Note) ||
    "";
  discount2 = discount2 ? discount2.substring(1, discount2.length - 1) : "";

  const variant1 =
    (product.VariantDetails &&
      product.VariantDetails[0] &&
      product.VariantDetails[0].Variant1Value.replace(/\s+/g, "+")) ||
    (product.PriceQuantity &&
      product.PriceQuantity[0] &&
      product.PriceQuantity[0].Variant1Value.replace(/\s+/g, "+")) ||
    "";
  const variant2 =
    (product.VariantDetails &&
      product.VariantDetails[0] &&
      product.VariantDetails[0].Variant2Value.replace(/\s+/g, "+")) ||
    (product.PriceQuantity &&
      product.PriceQuantity[0] &&
      product.PriceQuantity[0].Variant2Value.replace(/\s+/g, "+")) ||
    "";
  const productId = product.id;

  let brandId = product.id;


  const handleVariant1Change = (selectedOption) => {
    setSelectedVariant1(selectedOption);
    // Update variant2Values based on the selected variant1 value
    const filteredVariant2Values = Array.from(
      new Set(product?.VariantData
        .filter((variant) => variant.Variant1Value === selectedOption.value)
        .map((variant) => variant.Variant2Value))
    ).map(value => ({ value, label: value }));

    setVariant2Values(filteredVariant2Values);
    if (filteredVariant2Values.length > 0) {
      setSelectedVariant2(filteredVariant2Values[0]); // Set the first option as default
    }
    
    // Find the pvid based on selected Variant1
    const variantByPvid = product?.VariantData?.find(
      (res) => res.Variant1Value === selectedOption.value
    );

    const variantUSIN = product?.VariantData?.find(
      (res) => res.Variant1Value === selectedOption?.value && res.Variant2Value === selectedVariant2?.value
    );

    setPvid(variantByPvid?.pvid);
    setUSINData(variantUSIN?.USIN)
  };

  const handleVariant2Change = (selectedOption) => {
    setSelectedVariant2(selectedOption);
    // Find the pvid based on selected Variant2
    const variantByPvid = product?.VariantData?.find(
      (res) => res.Variant2Value === selectedOption.value
    );
    setPvid(variantByPvid?.pvid);
    const variantUSIN = product?.VariantData?.find(
      (res) => res.Variant1Value === selectedVariant1?.value && res.Variant2Value === selectedOption?.value
    );
    setUSINData(variantUSIN?.USIN) 
  };
 

  const submitAddToCart = async () => {
 
    if (fetchRegisterId()) {
      const result = await fetchProductQuantityInCartList(product.id, selectedVariant1?.value, selectedVariant2.value);
      if (result) {
        addToCart();
        setCountItems(prev => parseInt(prev) + 1);
        console.log("Clicked",product)
       
        moengage.track_event(`Add_to_Cart_Clicked`, {
          "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
         
          "Product ID": product.id,
          "Product Name": product.Name,
          "Base Price": product.VariantData[0].Mrp,
          "Promotion Price": product.VariantData[0].Price,
          "Discount Percentage": parseInt( product.VariantData[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
          [product.VariantData[0].Variant1Name? product.VariantData[0].Variant1Name  :""] :product.VariantData[0].Variant1Value,
          ...(product.VariantData[0].Variant2Name !== "" && {  [product.VariantData[0].Variant2Name ] :product.VariantData[0].Variant2Value }),
          "USIN": USINData , 
          "Screen Name" : screenName      
          
        });
      }
    } else {

      const requiredVariantData = product?.VariantData?.find(
        (res) => res.Variant1Value === selectedVariant1?.value && res.Variant2Value === selectedVariant2.value
      );
      
      const variantByPvid = product?.VariantData?.find(
        (res) => res.pvid === variantPvid
      );

      if (addProductToCookies(
        product.Name,
        product.id,
        1,
        requiredVariantData?.Price || price,
        requiredVariantData?.Mrp || comparePrice || comparePrice2,
        requiredVariantData?.Note || variantByPvid?.note,
        requiredVariantData?.pvid || variantPvid,
        requiredVariantData?.Variant1Value || variantByPvid?.Variant1Value,
        requiredVariantData?.Variant2Value,
        product.VariantDetails?.[0]?.CoverImage?.[0]?.image ||
          product.PriceQuantity?.[0]?.CoverImage?.[0]?.image,
        product?.PriceQuantity && product.PriceQuantity[0] && product.PriceQuantity[0].USIN || USINData,
      )) {
        console.log(product)

        moengage.track_event(`Add_to_Cart_Clicked`, {
          "ID": ``,
         
          "Product ID": product.id,
          "Product Name": product.Name,
          "Base Price": product.VariantData[0].Mrp,
          "Promotion Price": product.VariantData[0].Price,
          "Discount Percentage": parseInt( product.VariantData[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),  
          [product.VariantData[0].Variant1Name? product.VariantData[0].Variant1Name :product.PriceQuantity[0].Variant1Name] :product.VariantData[0].Variant1Value ? product.VariantData[0].Variant1Value :product.PriceQuantity[0].Variant1Value,
          ...(product.VariantData[0].Variant2Name !== "" && product.PriceQuantity[0].Variant2Name !== "" && {  [product.VariantData[0].Variant2Name ? product.VariantData[0].Variant2Name : product.PriceQuantity[0].Variant2Name] :product.VariantData[0].Variant2Value ? product.VariantData[0].Variant2Value :product.PriceQuantity[0].Variant2Value }),
          "USIN": USINData , 
          "Screen Name" : screenName      
          
        });
        setIsToastMessage(true);
        onAddtoCart({ id: product.id });
        setTimeout(() => {
          setIsToastMessage(false);
        }, 3000);
      }
  
      setCountItems(prev => parseInt(prev) + 1);
    }
  };
  
  
  useEffect(() => {
    if(currentSelected[currentSelected.length-1] === productId){
      setActivePop(true);
    }else{
      setActivePop(false);
    }
  }, [currentSelected, productId]);


  
  useEffect(() => {
    // Extract and filter unique Variant1 values
    const uniqueVariant1Values = Array.from(
      new Set(product?.VariantData?.map((variant) => variant.Variant1Value))
    ).map(value => ({ value, label: value })); // Convert to option format

    setVariant1Values(uniqueVariant1Values);
    if (uniqueVariant1Values.length > 0) {
      setSelectedVariant1(uniqueVariant1Values[0]); // Set the first option as default
    }
  }, [product]);

  useEffect(() => {
    if (selectedVariant1) {
      // Filter and set unique Variant2 values based on selected Variant1
      const filteredVariant2Values = Array.from(
        new Set(product?.VariantData
          .filter((variant) => variant.Variant1Value === selectedVariant1.value)
          .map((variant) => variant.Variant2Value))
      ).map(value => ({ value, label: value })); // Convert to option format

      setVariant2Values(filteredVariant2Values);
      if (filteredVariant2Values.length > 0) {
        setSelectedVariant2(filteredVariant2Values[0]); // Set the first option as default
      }
    }
  }, [selectedVariant1, product]); // Dependency on product to re-run when product data changes




 // Function to handle adding product to cart
const productAtcBtn = () => {
  if (onAddtoCart) {
    onAddtoCart(product);
  } else {
    addToCart();
  }


  const variant1Value = product?.VariantData?.find(
    (res) => res.Variant1Value === selectedVariant1?.value
  );

  const variant2Value = product?.VariantData?.find(
    (res) => res.Variant2Value === selectedVariant2?.value
  );
  
  setVariant1Value(variant1Value?.Variant1Value);
  setVariant2Value(variant2Value?.Variant2Value);
 
};

  
 

  let mainProductVariantId = 
  (Array.isArray(product?.PriceQuantity) && product?.PriceQuantity.length > 0 && product?.PriceQuantity[0]?.Variant1Id) || 
  (Array.isArray(product?.VariantDetails) && product?.VariantDetails.length > 0 && product?.VariantDetails[0]?.Variant1Id);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    option: (provided, state) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginTop: '6px',
      cursor: 'pointer',
      fontSize: '14px',
      backgroundColor: state.isFocused ? '#ec4e1e' : "", // Change background color on hover
      color: state.isFocused ? 'white' : 'black', // Change text color on hover
    }),
    singleValue: (provided) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };
   
  // Assuming product.Name, variant1, and variant2 are strings



  const handleClick = () => {
        if( product?.VariantDetails){
      moengage.track_event(`${from}`, {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
       "Brand Name" : product.BrandName,
      "Product Id" : product.id,
    "Product Name": product.Name,
    "Base Price" : product.VariantDetails[0].Mrp,    // product.PriceQuantity[0].Price,
    "Discount Percentage":  parseInt( product.VariantData[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),
    "Promotion Price":product.VariantDetails[0].Price,
    [product.VariantDetails[0].Variant1Name ? product.VariantDetails[0].Variant1Name :""] :product.VariantDetails[0].Variant1Value,
    ...(product.VariantDetails[0].Variant2Name !== "" && {  [ product.VariantDetails[0].Variant2Name ] : product.VariantDetails[0].Variant2Value }),
    "USIN": USINData,
    "ScreenName":screenName
         });
    }
    else if(product?.PriceQuantity){
    
   //  console.log(parseInt( product.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]))
      moengage.track_event(`${from}`, {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
       "Brand Name" : product.BrandName,
      "Product Id" : product.id,
    "Product Name": product.Name,
    "Base Price" : product.PriceQuantity[0].Mrp,    // product.PriceQuantity[0].Price,
    "Promotion Price":product.PriceQuantity[0].Price,
    "Discount Percentage": parseInt( product.PriceQuantity[0].Note.replace('(',"").replace(')',"").replace('%',"").split(" ")[0]),
   [product.PriceQuantity[0].Variant1Name ? product.PriceQuantity[0].Variant1Name :""] : product.PriceQuantity[0].Variant1Value  ,
   ...(product.PriceQuantity[0].Variant2Name !== "" && {  [ product.PriceQuantity[0].Variant2Name ] : product.PriceQuantity[0].Variant2Value })
  ,
   "USIN": USINData ,
    "ScreenName":screenName
         });
    }
    
  }



  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another way
    }
    return encodeURIComponent(
      str
        .replace(/[^a-zA-Z0-9.]/g, "-") // Replace non-alphanumeric characters with hyphens
        .replace(/-+/g, "-")            // Replace multiple hyphens with a single hyphen
        .replace(/^-|-$/g, "")          // Remove leading or trailing hyphens
        .toLowerCase()                  // Convert to lowercase
    );
  };

// Assuming product.Name, variant1, and variant2 are strings
const productUrlFixes = fixUrlString(product.Name);
const variant1UrlFixes = fixUrlString(selectedVariant1 || variant1);
const variant2UrlFixes = fixUrlString(selectedVariant2 || variant2);

const USIN2 = product?.VariantData?.filter((res) => 
  res.Variant1Value.toLowerCase() === fixUrlString(variant1)?.replace(/-/g, " ").toLowerCase() && 
  res.Variant2Value.toLowerCase() === fixUrlString(variant2)?.replace(/-/g, " ").toLowerCase()
);

let USINData2 = USIN2 && USIN2[0]?.USIN || USIN;

// USIN = USIN2 && USIN2[0]?.USIN;


  return (
    <div className={`product-card ${isOutOfStock && "product_not_available"}`}  onClick={() => handleClick()}>
      {isOutOfStock && (
        <Link
        to={`/product/${USINData2}/${productUrlFixes}/${fixUrlString(variant1)}/${fixUrlString(variant2)}?productid=${productId}`}
        >
          <div className="not_available_wrap">
            <div className="sold_out_label">
              <p>Out Of Stock</p>
            </div>
          </div>
        </Link>
      )}
      <div className="card-wrapper">
        <div className="card-item" data={brandId}>
          <div className="card-image"> 
            <Link
        to={`/product/${USINData2}/${productUrlFixes}/${fixUrlString(variant1)}/${fixUrlString(variant2)}?productid=${productId}`}
        >
              <img
                srcSet={`
                  ${image || image2} 165w,
                  ${image || image2} 360w,
                  ${image || image2} 533w,
                  ${image || image2} 720w,
                  ${image || image2} 940w,
                  ${image || image2} 1066w,
                  ${image || image2} 100w
                `}
                sizes={`
                  (min-width: ${pageWidth}px) ${Math.floor(
                  (pageWidth - 130) / 4
                )}px,
                  (min-width: 990px) calc((100vw - 130px) / 4),
                  (min-width: 750px) calc((100vw - 120px) / 3),
                  calc((100vw - 35px) / 2)
                `}
                src={image}
                alt={product.title}
                className="motion-reduce"
                loading="lazy"
                width={product.width || 533}
                height={product.height || 400}
              />

              {(discount || discount2) && (
                <div className="product__discount">
                  <span>{discount || discount2}</span>
                </div>
              )}
                
            </Link>
          </div>
          {/* <img className="wishList-button" src={wishListStatus == 1 ? HeartIcon : GreyHeartIcon} onClick={() => handleWishList(productId)}/> */}
          <div className="card-content">
            <Link
        to={`/product/${USINData2}/${productUrlFixes}/${fixUrlString(variant1)}/${fixUrlString(variant2)}?productid=${productId}`}
        >
              <div className="product_title">
                <div>{product.Name}</div>
              </div>
            </Link>
            <div className="product_price">
              <div className="price">
                <div className="actual_price">₹{price}</div>
                <div className="compare_price">
                  <s>₹{comparePrice || comparePrice2}</s>
                </div>
                {discount2 && (
                  <div className="product__discounts green">
                    <span>({discount2})</span>
                  </div>
                )}
                {discount && (
                  <div className="product__discounts green">
                    <span>({discount})</span>
                  </div>
                )}
              </div>
            </div>

            <div className="product_btns">
              <div
                className="product_atc_btn"
                onClick={() => productAtcBtn()}
              >
                <img src={CartIcon} alt="icon icon-cart" />

                <div className="btn_text">Add To Cart</div>
              </div>
            </div>
          </div>
        

          {/* Variant Popup */}
            <div className={`card_variant_main ${activePopup ? 'active_var' : ''}`}>
              <div className="card_var_title">
                <p>Select Option</p>
               
                <div className="close_btn cursor-pointer" onClick={() => onAddtoCart(product)}>
                  <img src={Close_Square} alt="close" />
                </div>
              </div>
              <div className="card_var_body">
                {/* Select Option for Variant 1 (e.g., Flavour or Size) */}
                {variant1 && (
                  <div className="card_var_items">
                    {mainProductVariantId === 6 ? (
                      <p>Flavour</p>
                    ) : mainProductVariantId === 7 ? (
                      <p>Size</p>
                    ) : (
                      ""
                    )}
                    <div className="card_var_select">
                      <Select
                        options={variant1Values}
                        value={selectedVariant1}
                        onChange={handleVariant1Change}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                )}

                {/* Select Option for Variant 2 (e.g., Size if it's not already chosen as Variant 1) */}
                {variant2 && (
                  <div className="card_var_items">
                    <p>Size</p>
                    <div className="card_var_select">
                    <Select
                        options={variant2Values}
                        value={selectedVariant2}
                        onChange={handleVariant2Change}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="card_var_footer">
                <div className="product_btns">
                  <div className="product_atc_btn" onClick={submitAddToCart}>
                    <img src={CartIcon} alt="icon icon-cart" />
                    <div className="btn_text">Add To Cart</div>
                  </div>
                  <div className="view_product_btn">
                    <a href={`/product/${USIN}/${productUrlFixes}/${fixUrlString(selectedVariant1?.value) || variant1}/${fixUrlString(selectedVariant2?.value) || variant2}?productid=${productId}`}>
                      View Product
                    </a>
                  </div>
                </div>
              </div>
            </div>
          {/* Variant Popup End */}

        </div>
      </div>
    </div>
  );
};

export default ProductCard;
